<script lang="ts" setup>
const emit = defineEmits(['button-click'])
</script>

<template>
  <button
    class="
      focus:outline-hidden flex items-center justify-center rounded-lg bg-white p-3 px-8 font-medium
      text-main-dark hover:text-main-green focus:ring-0
    "
    @click.prevent="emit('button-click')"
  >
    <slot />
  </button>
</template>
