<script lang="ts" setup>
const props = defineProps<{
  iconName: string
  showCatalogMenuStatus: boolean
  title: string
}>()

const currentIconName = ref(props.iconName)
const currentShowCatalogMenuStatus = ref(props.showCatalogMenuStatus)
const currentTitle = ref(props.title)

watch(() => props.iconName, (newValue) => {
  currentIconName.value = newValue
})

watch(() => props.showCatalogMenuStatus, (newValue) => {
  currentShowCatalogMenuStatus.value = newValue
})

watch(() => props.title, (newValue) => {
  currentTitle.value = newValue
})
</script>

<template>
  <span
    class="
      hidden cursor-pointer items-center justify-center rounded-2xl px-4 py-3 font-medium text-white
      transition-colors duration-300 ease-in-out
      md:flex
    "
    :class="{ 'bg-main-red': currentShowCatalogMenuStatus, 'bg-main-green': !currentShowCatalogMenuStatus }"
  >
    <SvgIcon class="mr-1 !text-2xl" :name="currentIconName" /> {{ currentTitle }}

    <div v-if="currentShowCatalogMenuStatus" class="pr-[1.5px]" />
  </span>
</template>
