<template>
  <div class="relative z-[1000]">
    <div class="
      bg-main-gray-low block border border-solid border-transparent rounded-[16px] px-5 w-full h-[48px]
      font-normal text-base leading-[1.37] tracking-[-0.01em] animate-pulse
    "></div>

    <div class="absolute flex h-full items-center top-[2px] right-4">
      <div class="ml-3 bg-main-light-gray h-6 w-6 rounded-full"></div>
    </div>
  </div>
</template>
