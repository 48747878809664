<script setup lang="ts">
import ProductCard from '~/components/cards/ProductCard.vue'
import SkeletonCard from '~/components/skeleton/SkeletonCard.vue'
import { useSearchboosterStore } from '~/store/searchbooster'

defineProps<{
  query: string
}>()

const searchboosterStore = useSearchboosterStore()
const { getPopularOffers, isSearchLoading } = storeToRefs(searchboosterStore)

onMounted(async (): Promise<void> => {
  if (!getPopularOffers.value.length) {
    await searchboosterStore.fetchPopularProducts()
  }
})
</script>

<template>
  <div v-if="getPopularOffers.length || isSearchLoading">
    <div class="text-base tracking-[-0.01em] text-main-dark md:text-lg">
      Не&nbsp;смогли найти <span class="font-semibold">«{{ query }}»</span>. Возможно, вам понравится:
    </div>

    <div class="-mx-1 mt-3 flex flex-wrap justify-start">
      <template v-if="isSearchLoading">
        <div v-for="n in 12" :key="n">
          <SkeletonCard />
        </div>
      </template>
      <template v-else>
        <div
          v-for="(x, index) in getPopularOffers"
          :key="index"
          class="w-1/2 p-1 lg:w-1/3 xl:w-1/4"
        >
          <ProductCard :has-button="false" :product-data="searchboosterStore.convertOfferToProductData(x)" />
        </div>
      </template>
    </div>
  </div>
</template>
