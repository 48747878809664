<script lang="ts" setup>
import Page from '~/enums/page'

const items = computed(() => {
  return [
    {
      link: Page.Partner,
      title: 'Специалистам'
    },
    {
      link: Page.Supplier,
      title: 'Поставщикам'
    },
    {
      link: Page.Distributor,
      title: 'Оптовым клиентам'
    },
    {
      link: 'https://itab.pro/app/',
      title: 'Мобильное приложение'
    }
  ]
})
</script>

<template>
  <div class="flex items-center justify-between">
    <NavLink
      v-for="(x, index) in items"
      :key="index"
      class="ml-3"
      :is-active="index === 0"
      :link="x.link"
      :title="x.title"
    />
  </div>
</template>
