<script lang="ts" setup>
import NewCatalogMenuPopup from '~/components/new-catalog-menu/NewCatalogMenuPopup.vue'
import NewCatalogMenuSearchInput from '~/components/new-catalog-menu/NewCatalogMenuSearchInput.vue'
import Page from '~/enums/page'
import { debounce } from '~/helpers/debounce-helper'
import { useSearchPopupStore } from '~/store/search-popup'
import { useSearchboosterStore } from '~/store/searchbooster'

const route = useRoute()
const router = useRouter()

const searchboosterStore = useSearchboosterStore()
const searchPopupStore = useSearchPopupStore()
const { isPopupOpen } = storeToRefs(searchPopupStore)

const debouncedSearch = debounce(async (signal: AbortSignal) => {
  await handlerSearchButtonClick(signal)
}, 300)

async function handlerCancelButtonClick (): Promise<void> {
  searchboosterStore.$reset()
  searchPopupStore.close()
}

async function handlerFocus (): Promise<void> {
  if (!isPopupOpen.value && route.path !== Page.Search) {
    searchPopupStore.open()

    await searchboosterStore.fetchCompletions()
  }
}

async function handlerKeyupEnter (): Promise<void> {
  searchboosterStore.clearSearchProducts()

  await router.push({ path: Page.Search })
  await searchboosterStore.fetchCompletions()
  await searchboosterStore.fetchSearch()
}

async function handlerSearchButtonClick (signal?: AbortSignal): Promise<void> {
  searchboosterStore.clearSearchProducts()

  await searchboosterStore.fetchCompletions({ signal })
  await searchboosterStore.fetchSearch({ signal })
}

watch(
  () => searchboosterStore.filter.query,
  () => {
    searchboosterStore.isSearchLoading = true
    debouncedSearch()
  }
)

watch(() => router.currentRoute.value, () => {
  if (isPopupOpen.value) {
    searchPopupStore.close()
  }
})
</script>

<template>
  <div class="mx-4 w-full lg:mx-6">
    <div class="relative flex w-full items-center">
      <NewCatalogMenuSearchInput
        v-model="searchboosterStore.filter.query"
        class="w-full max-w-[806px]"
        :placeholder="'Найти на\xA0iTAB'"
        @on-cancel-button-click="handlerCancelButtonClick"
        @on-focus="handlerFocus"
        @on-keyup-enter="handlerKeyupEnter"
        @on-search-button-click="handlerSearchButtonClick"
      />

      <a
        v-if="isPopupOpen"
        class="mx-3 block text-sm font-medium leading-[1.43] tracking-[-0.01em] text-main-green md:hidden"
        href="#"
        @click.prevent="handlerCancelButtonClick"
      >
        Отмена
      </a>

      <NewCatalogMenuPopup />
    </div>
  </div>
</template>
