<template>
  <div
    class="
      border-b border-solid border-[#E2E2E2] cursor-pointer text-main-dark transition-colors !no-underline
      flex items-center justify-between p-3
      hover:bg-main-gray-low animate-pulse
    "
  >
    <div class="relative flex items-center overflow-hidden w-full">
      <div class="w-10 h-10 bg-gray-200 rounded-full"></div>

      <div class="absolute ml-12 w-2/3 h-4 bg-gray-200 rounded"></div>
    </div>

    <div class="w-6 h-6 bg-gray-200 rounded"></div>
  </div>
</template>
