import { defineStore } from 'pinia'

import { useBodyOverflowControl } from '~/composables/use-body-overflow-control'

export const useCatalogPopupStore = defineStore('catalogPopup', {
  state: () => ({
    status: false
  }),
  getters: {
    isPopupOpen (state) {
      return state.status
    }
  },
  actions: {
    open () {
      const bodyOverflowControl = useBodyOverflowControl()
      this.status = true

      bodyOverflowControl.disable()
    },
    close () {
      const bodyOverflowControl = useBodyOverflowControl()

      this.status = false
      bodyOverflowControl.enable()
    }
  }
})
