<script setup lang="ts">
import ProductCard from '~/components/cards/ProductCard.vue'
import NewCatalogMenuPopupSubject from '~/components/new-catalog-menu/NewCatalogMenuPopupSubject.vue'
import NewCatalogMenuSearchEmpty  from '~/components/new-catalog-menu/NewCatalogMenuSearchEmpty.vue'
import NewCatalogMenuSearchItem from '~/components/new-catalog-menu/NewCatalogMenuSearchItem.vue'
import NewCatalogMenuSearchResult from '~/components/new-catalog-menu/NewCatalogMenuSearchResult.vue'
import NewCatalogMenuSearchTip from '~/components/new-catalog-menu/NewCatalogMenuSearchTip.vue'
import SkeletonCard from '~/components/skeleton/SkeletonCard.vue'
import SkeletonItem from '~/components/skeleton/SkeletonItem.vue'
import SkeletonTip from '~/components/skeleton/SkeletonTip.vue'
import { useSearchProductsObserver } from '~/composables/search-products-observer'
import Page from '~/enums/page'
// import ScrollHelper from '~/helpers/scroll-helper'
import { useSearchPopupStore } from '~/store/search-popup'
import { useSearchboosterStore } from '~/store/searchbooster'

const router = useRouter()

const searchboosterStore = useSearchboosterStore()
const {
  isLoading,
  isSearchLoading,
  getCompletionsBrands,
  getCompletionsCategories,
  getCompletionsExtraOffers,
  getHistory,
  getPopularTips,
  getSearchOffers,
  getSearchProducts,
  getTips
} = storeToRefs(searchboosterStore)
const searchPopupStore = useSearchPopupStore()
const { isPopupOpen } = storeToRefs(searchPopupStore)
useSearchProductsObserver()

// watch (() => isSearchLoading.value, () => {
//   if (!isSearchLoading.value) {
//     nextTick(() => {
//       if (searchboosterStore.filter.skip === 0) {
//         return
//       }
//
//       ScrollHelper.more('#popup-modal', '.product-card')
//     })
//   }
// })
</script>

<template>
  <SearchPopup v-if="isPopupOpen" @click.self="searchPopupStore.close">
    <div
      v-if="searchboosterStore.filter.query !== null && searchboosterStore.filter.query.length > 0"
      class="flex h-max flex-col"
      itemscope
      itemtype="https://schema.org/SearchAction"
    >
      <template
        v-if="
          getCompletionsBrands.length
            || getCompletionsCategories.length
            || getTips.length
            || getSearchProducts.length
            || isLoading
            || isSearchLoading
        "
      >
        <template v-if="getTips.length || isLoading">
          <div class="flex-start -ml-1 mb-4 flex flex-wrap items-center" itemprop="query-input">
            <template v-if="isLoading">
              <div v-for="n in 6" :key="n" class="p-1">
                <SkeletonTip />
              </div>
            </template>
            <template v-else>
              <div v-for="(x, i) in getTips" :key="i" class="p-1">
                <NewCatalogMenuSearchTip
                  :name="x.text.value"
                  @click.prevent="searchboosterStore.initCompletionsByQuery(x)"
                />
              </div>
            </template>
          </div>
        </template>

        <template v-if="getCompletionsBrands.length || getCompletionsCategories.length || isLoading">
          <template v-if="isLoading">
            <div v-for="n in 6" :key="n">
              <SkeletonItem />
            </div>
          </template>
          <template v-else>
            <NewCatalogMenuSearchResult
              v-for="(x, i) in getCompletionsBrands"
              :key="i"
              itemprop="result"
              :name="x.text.value"
              @click.prevent="searchboosterStore.initSearchByQuery(x)"
            />

            <NewCatalogMenuSearchResult
              v-for="(x, i) in getCompletionsCategories"
              :key="i"
              class="last:!border-0"
              is-category
              itemprop="result"
              :name="x.text.value"
              @click.prevent="router.push({ path: x.url?.replace(/^https?:\/\/[^/]+/, '') })"
            />
          </template>
        </template>

        <template v-if="getSearchOffers.length || isSearchLoading">
          <div class="-mx-2 flex flex-wrap justify-start">
            <template v-if="isSearchLoading">
              <div v-for="n in 12" :key="n" class="w-1/2 p-1 lg:w-1/3 xl:w-1/4">
                <SkeletonCard />
              </div>
            </template>
            <template v-else>
              <div
                v-for="(x, index) in getSearchProducts"
                :key="index"
                class="w-1/2 p-1 lg:w-1/3 xl:w-1/4"
                itemscope
                itemtype="https://schema.org/Product"
              >
                <ProductCard class="!rounded-xl" :has-button="false" itemprop="itemListElement" :product-data="x" />
              </div>
            </template>
          </div>
        </template>

        <div
          v-if="
            searchboosterStore.searchMeta
              && searchboosterStore.searchMeta.hits > (searchboosterStore.filter.skip + searchboosterStore.filter.limit)
          "
          class="
            sticky
            bottom-0 z-50 -ml-4 flex h-[72px] w-[calc(100%+2rem)] items-center justify-center bg-main-gray-low
            md:-ml-7 md:w-[calc(100%+3.5rem)]
          "
        >
          <LoadMoreButton @button-click="router.push({ path: Page.Search })">
            Показать всё
          </LoadMoreButton>
        </div>
      </template>
      <NewCatalogMenuSearchEmpty v-else :query="searchboosterStore.filter.query" />
    </div>
    <div v-else class="h-max">
      <template v-if="getHistory.length || isLoading">
        <NewCatalogMenuPopupSubject class="mb-3 md:mb-5" subject="История" />

        <div class="flex min-w-64 flex-wrap items-center">
          <template v-if="isLoading">
            <div v-for="n in 6" :key="n" class="mb-3 mr-3">
              <SkeletonTip />
            </div>
          </template>
          <template v-else>
            <NewCatalogMenuSearchItem
              v-for="(x, index) in getHistory"
              :key="index"
              class="mb-3 mr-3"
              itemprop="query-input"
              :name="x.search.query"
              @click.prevent="searchboosterStore.initSearchByQuery(x)"
            />
          </template>
        </div>
      </template>

      <template v-if="getPopularTips.length || isLoading">
        <NewCatalogMenuPopupSubject class="mb-3 mt-2 md:mb-5" subject="Популярные запросы" />

        <div class="flex min-w-64 flex-wrap items-center">
          <template v-if="isLoading">
            <div v-for="n in 6" :key="n" class="p-1">
              <SkeletonTip class="mb-3 mr-3" />
            </div>
          </template>
          <template v-else>
            <NewCatalogMenuSearchItem
              v-for="(x, index) in getPopularTips"
              :key="index"
              class="mb-3 mr-3"
              itemprop="query-input"
              :name="x.search.query"
              @click.prevent="searchboosterStore.initSearchByQuery(x)"
            />
          </template>
        </div>
      </template>

      <template v-if="getCompletionsExtraOffers.length || isSearchLoading">
        <NewCatalogMenuPopupSubject class="mb-3 mt-2 md:mb-5" subject="Возможно, вам понравится" />

        <div class="-mx-1 flex flex-wrap justify-start pb-7">
          <template v-if="isSearchLoading">
            <div v-for="n in 4" :key="n" class="w-1/2 p-1 lg:w-1/3 xl:w-1/4">
              <SkeletonCard />
            </div>
          </template>
          <template v-else>
            <div
              v-for="(x, index) in getCompletionsExtraOffers"
              :key="index"
              class="w-1/2 p-1 lg:w-1/3 xl:w-1/4"
              itemscope
              itemtype="https://schema.org/Product"
            >
              <ProductCard
                class="!rounded-xl"
                :has-button="false"
                itemprop="itemListElement"
                :product-data="searchboosterStore.convertItemToProductData(x)"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
  </SearchPopup>
</template>
