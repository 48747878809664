<script lang="ts" setup>
import constants from '~/constants'
import Page from '~/enums/page'

const leftItems = [
  { title: 'Подбор БАД', url: constants.testsUrl },
  { path: Page.Specialists, title: 'Консультации' },
  // { path: '#', title: 'Онлайн-курсы' },
  { path: Page.Articles, title: 'Экспертный блог' },
  { path: Page.About, title: 'О нас' },
]

const rightItems = [
  { color: 'text-[#EE6A32]', path: Page.SpecialOffers, title: 'Акции' },
  { color: 'text-[#EE6A32]', path: `${Page.Products}?sort=newest`, title: 'Новинки' },
  { color: 'text-black', path: `${Page.Categories}skidki-etoy-nedeli-89/`, title: 'Скидки недели' },
]
</script>

<template>
  <nav
    class="hidden items-center justify-between md:flex"
    itemscope
    itemtype="https://schema.org/SiteNavigationElement"
  >
    <div>
      <RouteLink
        v-for="(x, index) in leftItems"
        :key="index"
        class="mr-3 lg:mr-6"
        itemprop="url"
        :path="x.path"
        :title="x.title"
        :url="x.url"
      />
    </div>

    <div>
      <RouteLink
        v-for="(x, index) in rightItems"
        :key="index"
        class="ml-3 lg:ml-6"
        :color="x.color"
        itemprop="url"
        :path="x.path"
        :title="x.title"
        :url="x.url"
      />
    </div>
  </nav>
</template>
