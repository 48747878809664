<script lang="ts" setup>
import NewCatalogMenuLink from '~/components/new-catalog-menu/NewCatalogMenuLink.vue'
import type { NewCategoryMenuItem } from '~/types/menus'

defineProps<{
  items: NewCategoryMenuItem[]
}>()

const size = ref(5)
</script>

<template>
  <li v-for="(y, j) in items?.slice(0, size)" :key="j" class="mb-1">
    <NewCatalogMenuLink :data="y" :is-parent="false" />

    <a
      v-if="j === 4 && size !== items.length"
      class="mt-1 flex items-center font-medium text-main-aluminum !no-underline"
      href="#"
      @click.prevent="size = items.length"
    >
      <span>Ещё</span>

      <SvgIcon name="outlined/small-arrow-down" />
    </a>
  </li>
</template>
