<script lang="ts" setup>
import NewCatalogMenuBody from '~/components/new-catalog-menu/NewCatalogMenuBody.vue'
import NewCatalogMenuPrinter from '~/components/new-catalog-menu/NewCatalogMenuPrinter.vue'
import NewCatalogMenuSidebar from '~/components/new-catalog-menu/NewCatalogMenuSidebar.vue'
import NewCatalogMenuTitleLink from '~/components/new-catalog-menu/NewCatalogMenuTitleLink.vue'
import NewCatalogMenuWrapper from '~/components/new-catalog-menu/NewCatalogMenuWrapper.vue'
import catalogMenuItems from '~/constants/catalog-menu-items'
import helpers from '~/helpers'

const sidebarStatus = ref(true)
const sidebarRef = ref()

const activeItem = computed(() => catalogMenuItems[sidebarRef.value?.activeItemIndex])
const categoryPath = computed(() => {
  return helpers.getCategoryPath({
    id: activeItem.value?.categoryId,
    attributes: { slug: activeItem.value?.categorySlug }
  })
})
const name = computed(() => activeItem.value?.name)
const items = computed(() => activeItem.value?.items ?? [])
</script>

<template>
  <div
    class="absolute left-0 top-[118px] z-40 h-[calc(100vh-118px)] w-full bg-white"
    itemscope
    itemtype="https://schema.org/CollectionPage"
  >
    <NewCatalogMenuWrapper>
      <NewCatalogMenuSidebar
        ref="sidebarRef"
        :class="{ 'hidden sm:block': !sidebarStatus }"
        :items="catalogMenuItems"
        @selected="sidebarStatus = false"
      />

      <NewCatalogMenuBody :class="{ 'hidden sm:block': sidebarStatus }">
        <a
          class="mb-7 flex items-center sm:hidden"
          href="#"
          @click.prevent="sidebarStatus = true"
        >
          <SvgIcon class="mr-1 block !size-6" name="outlined/arrow-back" />
          <span class="text-sm font-medium leading-[143%] tracking-[-0.01em] text-black">Все категории</span>
        </a>

        <NewCatalogMenuTitleLink class="mb-5" itemprop="name" :link="categoryPath" :title="name" />

        <div class="flex flex-wrap items-stretch">
          <NewCatalogMenuPrinter :data="items" />
        </div>
      </NewCatalogMenuBody>
    </NewCatalogMenuWrapper>
  </div>
</template>
