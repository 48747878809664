import { useModal } from 'vue-final-modal'

import Popup from '~/components/modal-windows/Popup.vue'
import type { PopupData } from '~/types/popups'

const localStorageKey = 'popupsClosedAt'

export function usePopups () {
  const popupsClosedAt = ref<Record<string, number>>(
    JSON.parse(localStorage.getItem(localStorageKey) ?? '{}')
  )

  function needToShowPopup (popupData: PopupData): boolean {
    const popupClosedAt = popupsClosedAt.value[popupData.id]

    if (popupClosedAt === undefined) {
      return true
    }

    const { delayAfterClosing } = popupData.attributes

    if (delayAfterClosing === null) {
      return false
    }

    return (Date.now() - popupClosedAt) / 1000 >= (delayAfterClosing ?? 0)
  }

  function setPopupClosedAt (popupId: string): void {
    popupsClosedAt.value[popupId] = Date.now()
    localStorage.setItem(localStorageKey, JSON.stringify(popupsClosedAt.value))
  }

  function showPopupIfNeeded (popupData: PopupData): void {
    if (!needToShowPopup(popupData)) {
      return
    }

    setTimeout(
      async (): Promise<void> => {
        if (!needToShowPopup(popupData)) {
          return
        }

        await useModal({ component: Popup, attrs: { popupData } }).open()
      },
      1000 * (popupData.attributes.delayBeforeShowing ?? 0)
    )
  }

  return { setPopupClosedAt, showPopupIfNeeded }
}
