<script lang="ts" setup>
import constants from '~/constants'
import Page from '~/enums/page'
import type { AppMarketplaceMenuItem } from '~/types/footer'

const { appStore, googlePlay } = constants.appUrls

const appMarketplaceMenuItems: AppMarketplaceMenuItem[] = [
  { href: googlePlay, iconName: 'app-marketplaces/google-play', name: 'Google Play' },
  { href: appStore, iconName: 'app-marketplaces/app-store', name: 'App Store' }
]
const bottomItems = [
  { color: 'text-[#ee6a32]', path: Page.SpecialOffers, title: 'Акции' },
  { color: 'text-[#ee6a32]', path: `${Page.Products}?sort=newest`, title: 'Новинки' },
  { color: 'text-black', path: `${Page.Categories}skidki-etoy-nedeli-89/`, title: 'Скидки недели' },
]
const topItems = [
  { icon: 'outlined/cats', path: Page.Search, title: 'Каталог' },
  { title: 'Подбор БАД', url: constants.testsUrl },
  { path: Page.Specialists, title: 'Консультации' },
  { path: Page.About, title: 'О нас' },
  // { path: '#', title: 'Онлайн-курсы' },
  { path: Page.Articles, title: 'Экспертный блог' },
]
</script>

<template>
  <div class="absolute left-0 top-[118px] z-40 h-[calc(100vh-118px)] w-full bg-main-gray-low">
    <div class="custom-scroll h-[90%] overflow-y-auto px-2">
      <nav class="flex flex-col" itemscope itemtype="https://schema.org/SiteNavigationElement">
        <NuxtLink
          v-for="(x, index) in [...topItems, ...bottomItems]"
          :key="index"
          class="
            flex items-center justify-between border-b border-solid
            border-[#e2e2e2] px-2 py-3 text-sm font-medium leading-[1.43] tracking-[-0.01em] text-main-dark
            last:border-0
          "
          itemprop="url"
          :target="x.url ? '_blank' : undefined"
          :to="x.path ?? x.url"
        >
          <div class="flex items-center">
            <SvgIcon v-if="x.icon" class="mr-3 !size-6" :name="x.icon" />
            <span>
              {{ x.title }}
            </span>
          </div>
          <SvgIcon class="!size-6" name="outlined/arrow-next" />
        </NuxtLink>

        <div class="flex flex-col px-2 py-3">
          <strong class="!text-sm !font-medium">Мобильное приложение</strong>

          <div class="mb-4 mt-3 text-xs text-main-silver">
            Скачивайте в&nbsp;магазинах
          </div>

          <div class="flex items-center">
            <a
              v-for="(x, index) in appMarketplaceMenuItems"
              :key="index"
              class="mr-6 flex items-center !text-main-dark"
              :href="x.href"
              itemprop="url"
              target="_blank"
            >
              <SvgIcon class="mr-3 !size-9" :name="x.iconName" />
              <span>
                {{ x.name }}
              </span>
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/custom-scroll';
</style>
