<script lang="ts" setup>
import NewCatalogMenuBox from '~/components/new-catalog-menu/NewCatalogMenuBox.vue'
import NewCatalogMenuSidebarLink from '~/components/new-catalog-menu/NewCatalogMenuSidebarLink.vue'
import type { NewCategoryMenuItem } from '~/types/menus'

defineProps<{
  items: NewCategoryMenuItem[]
}>()

const emit = defineEmits<{
  (e: 'selected'): void
}>()

const activeItemIndex = ref(0)

defineExpose({
  activeItemIndex
})
</script>

<template>
  <div class="w-full sm:-ml-3 sm:mr-4 sm:w-1/2 lg:w-1/4">
    <NewCatalogMenuBox>
      <ul
        class="
          custom-scroll relative h-[calc(100vh-236px)] overflow-x-hidden overflow-y-scroll pb-48
          md:h-[calc(100vh-118px)] md:overflow-y-auto 2xl:h-min 2xl:pb-0
        "
      >
        <li v-for="(x, i) in items" :key="i">
          <NewCatalogMenuSidebarLink
            :data="x"
            :is-active="activeItemIndex === i"
            @click="activeItemIndex = i; emit('selected')"
          />
        </li>
      </ul>
    </NewCatalogMenuBox>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/custom-scroll';

.custom-scroll {
  overflow-y: scroll;
  padding-right: 1rem;

  &::-webkit-scrollbar-track {
    background: #eee !important;
  }
}
</style>
