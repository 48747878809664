<template>
  <div
    class="
      bg-gray-200 cursor-pointer px-9 py-3.5
      text-transparent text-sm tracking-[-0.01em] transition-colors rounded-2xl
      animate-pulse
    "
  >
    <span class="block w-full h-full rounded bg-gray-300"></span>
  </div>
</template>
