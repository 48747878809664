<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import Indicator from "~/components/Indicator.vue";
import BottomMenu from '~/components/menus/BottomMenu.vue'
import NewCatalogMenu from '~/components/menus/NewCatalogMenu.vue'
import NewMobileMenu from '~/components/menus/NewMobileMenu.vue'
import TopMenu from '~/components/menus/TopMenu.vue'
import Auth from '~/components/modal-windows/Auth.vue'
import NewCatalogMenuOpenButton from '~/components/new-catalog-menu/NewCatalogMenuOpenButton.vue'
import NewCatalogMenuSearch from '~/components/new-catalog-menu/NewCatalogMenuSearch.vue'
import { useBodyOverflowControl } from '~/composables/use-body-overflow-control'
import AuthAction from '~/enums/auth-action'
import Page from '~/enums/page'
import { useAuthStore } from '~/store/auth'
import { useCatalogPopupStore } from '~/store/catalog-popup'
import { useProductsStore } from '~/store/products'
import { useSearchPopupStore } from '~/store/search-popup'
import { useUserStore } from '~/store/user'
import type { SignInMenuItem } from '~/types/menus'

const isHidden = ref(false)

const authStore = useAuthStore()
const { isUser } = storeToRefs(authStore)
const catalogPopupStore = useCatalogPopupStore()
const { isPopupOpen: isCatalogPopupOpen } = storeToRefs(catalogPopupStore)
const productsStore = useProductsStore()
const { cartTotalItems, wishListTotalItems } = storeToRefs(productsStore)
const searchPopupStore = useSearchPopupStore()
const { isPopupOpen } = storeToRefs(searchPopupStore)
const userStore = useUserStore()
const { hasAccessToAdminPanel, isSpecialist, isSupplier } = storeToRefs(userStore)

const bodyOverflowControl = useBodyOverflowControl()
const route = useRoute()
const router = useRouter()

const query = ref(null)
const showMobileMenu = ref(false)

let lastScrollPosition = 0

const signInMenuItems = computed(() => {
  const items: SignInMenuItem[] = []

  if (isUser.value) {
    const additionalItems = [
      { condition: true, name: 'ЛК клиента', path: Page.Account },
      { condition: isSpecialist.value, name: 'ЛК специалиста', path: Page.Specialist },
      { condition: isSupplier.value, name: 'ЛК поставщика', path: Page.Supplier },
      { condition: hasAccessToAdminPanel.value, name: 'Админпанель', path: Page.Admin }
    ]

    additionalItems.forEach((item => {
      if (item.condition) {
        items.push({ isAuthRequired: true, name: item.name, path: item.path })
      }
    }))
  }

  return items
})

function closeMobileMenu (): void {
  showMobileMenu.value = false

  bodyOverflowControl.enable()
}

function handleScroll (): void {
  if (route.path === Page.Search) {
    return
  }

  const currentScrollPosition = window.scrollY

  isHidden.value = !isPopupOpen.value
    && !isCatalogPopupOpen.value
    && !showMobileMenu.value
    && currentScrollPosition > lastScrollPosition
    && currentScrollPosition > 122

  lastScrollPosition = currentScrollPosition
}

function openMobileMenu (): void {
  showMobileMenu.value = true

  bodyOverflowControl.disable()
}

function toggle (): void {
  if (isCatalogPopupOpen.value) {
    catalogPopupStore.close()
  } else {
    catalogPopupStore.open()
  }
}

function toggleMobileMenu (): void {
  if (showMobileMenu.value) {
    closeMobileMenu()
  } else {
    openMobileMenu()
  }
}

async function openAuthModalWindow (): Promise<void> {
  await useModal({ component: Auth, attrs: { action: AuthAction.SignIn } }).open()
}

watch(() => router.currentRoute.value, () => {
  if (isCatalogPopupOpen.value) {
    catalogPopupStore.close()
  }
})

onMounted((): void => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted((): void => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <div
    class="sticky top-0 z-[999] w-full bg-main-gray-low transition-all duration-300 ease-in-out md:bg-white"
    :class="{ '-translate-y-full opacity-0': isHidden }"
  >
    <Container class="pb-2.5 md:pb-4">
      <div class="hidden items-center justify-between border-b border-solid border-[#eee] py-3 md:flex">
        <CityButton />

        <TopMenu />
      </div>

      <div>
        <div class="flex flex-col items-center justify-between md:flex-row md:pt-2.5">
          <div class="flex w-full items-center justify-between py-4 md:w-auto md:py-0">
            <div class="w-6">
              <div class="md:hidden">
                <a
                  v-if="isPopupOpen"
                  class="flex items-center text-sm font-medium !text-main-dark"
                  href="#"
                  @click.prevent="searchPopupStore.close"
                >
                  <SvgIcon class="!size-6" color="black" name="outlined/arrow-back" />
                  <span class="ml-1.5 block">Назад</span>
                </a>
              </div>
            </div>

            <Logo class="md:mr-6" @click="catalogPopupStore.close" />

            <Burger class="mr-4" :is-open="showMobileMenu" @click="toggleMobileMenu" />

            <NewCatalogMenuOpenButton
              :icon-name="isCatalogPopupOpen ? 'outlined/arrow-left' : 'outlined/grid-view'"
              :show-catalog-menu-status="isCatalogPopupOpen"
              :title="isCatalogPopupOpen ? 'Назад&nbsp;&nbsp;&nbsp;' : 'Каталог'"
              @click="toggle"
            />
          </div>

          <NewCatalogMenuSearch v-model="query" />

          <div class="hidden !min-w-[230.73px] !max-w-[230.73px] items-stretch justify-between md:flex">
            <div class="relative">
              <Indicator v-if="wishListTotalItems" class="right-0" :value="wishListTotalItems" />

              <IconButton icon="outlined/favorites" :link="Page.Favorites" title="Избранное" />
            </div>

            <div class="relative">
              <Indicator v-if="cartTotalItems" class="right-0" :value="cartTotalItems" />

              <IconButton icon="outlined/cart" :link="Page.Cart" title="Корзина" />
            </div>

            <DropdownMenu v-if="isUser" class="fixed !z-50" icon-name="outlined/account" title="Профиль">
              <DropdownMenuItem
                v-for="x in signInMenuItems"
                :key="x.path"
                class="more-menu-item whitespace-nowrap"
                @click.prevent="router.push({ path: x.path })"
              >
                {{ x.name }}
              </DropdownMenuItem>
            </DropdownMenu>
            <IconButton
              v-else
              icon="outlined/account"
              title="Войти"
              @click.prevent="openAuthModalWindow"
            />
          </div>
        </div>

        <BottomMenu class="pt-3" />
      </div>
    </Container>

    <Transition>
      <NewCatalogMenu v-show="isCatalogPopupOpen" />
    </Transition>

    <Transition>
      <NewMobileMenu v-show="showMobileMenu" />
    </Transition>
  </div>
</template>
