<script lang="ts" setup>
import SkeletonInput from '~/components/skeleton/SkeletonInput.vue'

const modelValue = defineModel<string | number | null>()

withDefaults(
  defineProps<{
    placeholder?: string
  }>(),
  { placeholder: undefined }
)

const emit = defineEmits<{
  (e: 'onCancelButtonClick'): void
  (e: 'onFocus'): void
  (e: 'onKeyupEnter'): void
  (e: 'onSearchButtonClick'): void
}>()

const isPageLoading = ref(true)
const input = ref<HTMLInputElement | null>(null)

function onCancelButtonClick (): void {
  modelValue.value = null

  emit('onCancelButtonClick')
}

onMounted(() => {
  isPageLoading.value = false
})
</script>

<template>
  <div class="relative z-[1000]">
    <SkeletonInput v-if="isPageLoading" />
    <input
      v-else
      ref="input"
      v-model="modelValue"
      autocomplete="off"
      class="
        block h-[48px] w-full rounded-[16px] border border-solid border-transparent bg-white px-5
        text-base font-normal leading-[1.37] tracking-[-0.01em] focus:outline-none focus:ring-0
        sm:border-main-gray-low
        md:bg-main-gray-low
      "
      :disabled="isPageLoading"
      :placeholder="placeholder"
      type="text"
      @focus="emit('onFocus')"
      @keyup.enter="emit('onKeyupEnter'); input?.blur()"
    >

    <div class="absolute right-4 top-[2px] flex h-full items-center">
      <a v-show="modelValue" href="#" @click.prevent="onCancelButtonClick">
        <SvgIcon class="!size-6 fill-black" name="outlined/clear" />
      </a>

      <a v-show="!modelValue" href="#" @click.prevent="emit('onSearchButtonClick')">
        <SvgIcon class="!size-6" name="outlined/new-search" />
      </a>
    </div>
  </div>
</template>
