<script lang="ts" setup>
import helpers from '~/helpers'
import type { NewCategoryMenuItem } from '~/types/menus'

const props = withDefaults(
  defineProps<{
    data: NewCategoryMenuItem
    isParent?: boolean
  }>(),
  { isParent: false }
)

const categoryPath = computed(() => {
  const { categoryId: id, categorySlug: slug } = props.data

  return id === null || slug === null ? undefined : helpers.getCategoryPath({ id, attributes: { slug } })
})
</script>

<template>
  <NuxtLink
    class="
      block max-w-[90%] text-base leading-normal tracking-[-0.01em] text-[#6E6E6E] !no-underline
      hover:text-main-green
    "
    :class="{ '!text-main-dark': isParent }"
    itemprop="url"
    :to="categoryPath"
  >
    <span itemprop="name">
      {{ data.name }}
    </span>
  </NuxtLink>
</template>
