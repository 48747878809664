<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import { useInteraction } from '~/composables/interaction'
import { usePopups } from '~/composables/popups'
import type { PopupData } from '~/types/popups'

const model = defineModel<boolean>()

const props = defineProps<{
  popupData: PopupData
}>()

const { onClick } = useInteraction()
const { setPopupClosedAt } = usePopups()

const attributes = computed(() => props.popupData.attributes)
const buttonText = computed(() => attributes.value.buttonText)
const descriptionHtml = computed(() => attributes.value.descriptionHtml)
const imageUrl = computed(() => props.popupData.meta.imageUrl)
const name = computed(() => attributes.value.name)

function close (): void {
  model.value = false
}

function closed (): void {
  setPopupClosedAt(props.popupData.id)

  console.log('closed', props.popupData.id) // TODO: Use Yandex Metrika goal.
}

async function onPopupClick (): Promise<void> {
  await onClick(props.popupData)

  close()

  console.log('onPopupClick', props.popupData.id) // TODO: Use Yandex Metrika goal.
}

function opened (): void {
  console.log('opened', props.popupData.id) // TODO: Use Yandex Metrika goal.
}
</script>

<template>
  <CustomModalWindow v-model="model" size="large" @closed="closed" @opened="opened">
    <template #title>
      {{ name }}
    </template>

    <div v-if="descriptionHtml" class="mb-4" v-html="descriptionHtml" />

    <div v-if="imageUrl" class="relative h-0 cursor-pointer pb-[56.25%]" @click="onPopupClick">
      <img
        v-lazy="imageUrl"
        :alt="name"
        class="absolute size-full min-h-full rounded-xl object-contain"
        loading="lazy"
      >
    </div>

    <template #action>
      <BasicButton v-if="buttonText" color="green" :title="buttonText" @click="onPopupClick" />
      <a v-else href="#" @click.prevent="close">Закрыть</a>
    </template>
  </CustomModalWindow>
</template>
