<template>
  <div class="fixed inset-0 z-[101] flex items-center justify-center md:bg-main-dark/50">
    <div
      id="popup-modal"
      class="
        custom-scroll absolute left-0 top-[122px] mx-auto my-0 h-[calc(100%-170px)] w-full
        min-w-[280px] overflow-y-scroll bg-main-gray-low p-4 !pb-0
        sm:min-w-[576px]
        md:-right-8 md:h-auto md:max-h-[80vh] md:max-w-screen-md md:overflow-y-auto md:rounded-xl md:bg-main-gray-low md:p-7 md:pb-0
        lg:max-w-[807px]
      "
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/custom-scroll';
</style>
